// src/components/Header/LatestSetsDrawer.js
// src/components/Header/LatestSetsDrawer.js
import React, { useState, useEffect } from 'react';
import { Drawer, Typography, Grid, Card, CardContent, Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { formatDistanceToNow, parseISO } from 'date-fns';

import PendingIcon from '@mui/icons-material/AssignmentTurnedInOutlined'; // Outline for Pending
import WorkIcon from '@mui/icons-material/BuildOutlined'; // Outline for In Progress
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline'; // Outline for Resolved
import CloseIcon from '@mui/icons-material/CancelOutlined'; // Outline for Closed
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'; // Outline for On Hold
import WarningIcon from '@mui/icons-material/WarningAmberOutlined'; // Outline for Escalated
import NotListedLocationIcon from '@mui/icons-material/RemoveCircleOutline'; // Outline for Rejected

// Helper function to format the date to a relative time string
const timeAgo = (dateString, prefix = '') => {
    const date = parseISO(dateString);
    return `${prefix} ${formatDistanceToNow(date, { addSuffix: true })}`;
};

const LatestSetsDrawer = ({ open, onClose }) => {
    const [latestSets, setLatestSets] = useState([]);
    const [inquiries, setInquiries] = useState([]);
    const [allInquiries, setAllInquiries] = useState([]);
    const [page, setPage] = useState(1);
    const [totalSets, setTotalSets] = useState(0);
    const [totalInquiries, setTotalInquiries] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);

    const statusStyles = {
        Pending: { color: '#FFA500', icon: <PendingIcon /> }, // Orange
        'In Progress': { color: '#2196F3', icon: <WorkIcon /> }, // Blue
        Resolved: { color: '#4CAF50', icon: <CheckCircleIcon /> }, // Green
        Closed: { color: '#B0BEC5', icon: <CloseIcon /> }, // Gray
        'On Hold': { color: '#FFD54F', icon: <HourglassEmptyIcon /> }, // Yellow
        Escalated: { color: '#FF5722', icon: <WarningIcon /> }, // Red
        Rejected: { color: '#F44336', icon: <NotListedLocationIcon /> }, // Dark Red
    };

    const fetchLatestSets = async (page) => {
        setLoadingMore(true);
        try {
            const response = await axios.get(`/client/api/dashboard/latest-notifications-data?page=${page}`);
            console.log("Response from Latest Sets API:", response.data);

            if (response.data) {
                if (Array.isArray(response.data.sets)) {
                    setLatestSets((prevSets) => [...prevSets, ...response.data.sets]);
                    setTotalSets(response.data.totalSets);
                }

                if (Array.isArray(response.data.inquiries)) {
                    setInquiries((prevInquiries) => {
                        const existingIds = new Set(prevInquiries.map(i => i.uuid));
                        const newInquiries = response.data.inquiries.filter(inquiry => !existingIds.has(inquiry.uuid));
                        return [...prevInquiries, ...newInquiries];
                    });
                    setTotalInquiries(response.data.totalInquiries);
                }

                if (Array.isArray(response.data.allInquiries)) {  // Handle all inquiries data
                    setAllInquiries(response.data.allInquiries);
                }
            } else {
                console.error("No sets or inquiries found in response:", response.data);
            }
        } catch (error) {
            console.error('Error fetching latest sets and inquiries:', error);
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    useEffect(() => {
        if (open) {
            setLatestSets([]);
            setInquiries([]);
            setAllInquiries([]);  // Reset all inquiries on open
            setPage(1);
            setLoading(true);
            fetchLatestSets(1);
        }
    }, [open]);

    const handleShowMore = () => {
        const nextPage = page + 1;
        setPage(nextPage);
        fetchLatestSets(nextPage);
    };

    const formatTypeLink = (typeLink) => {
        return typeLink
            .replace(/-/g, ' ') // Replace all dashes with spaces
            .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first character of each word
    };

    const handleLinkClick = () => {
        onClose(); // Close the drawer when a link is clicked
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <div style={{ width: '400px', padding: '16px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                    <Typography variant="h6" gutterBottom>
                        What’s New (this week)
                    </Typography>
                    {latestSets.length === 0 && inquiries.length === 0 ? (
                        <Typography variant="body1">
                            No notifications
                        </Typography>
                    ) : (
                        <Typography variant="body1" sx={{ fontSize: '14px' }}>
                            Notifications: {totalSets + totalInquiries}
                        </Typography>
                    )}
                </div>

                <Grid container spacing={2} justifyContent="center">
                    {loading ? ( // Show loader while fetching data
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                <CircularProgress />
                            </div>
                        </Grid>
                    ) : (
                        <>
                            {/* Inquiries Section */}
                            <Grid item xs={12}>
                                <Typography variant="h6" style={{ margin: '2px 0', borderBottom: '1px solid #ccc', fontSize: '15px' }}>
                                    New Inquiries Submitted
                                </Typography>
                            </Grid>
                            {inquiries.length > 0 ? (
                                inquiries.map(item => (
                                    <Grid item xs={12} key={item._id}>
                                        <Link to={`/v1/portal/csutc/dashboard/${item.typeLink}/details/${item.uuid}?refer=notifications&whatsnew&drwno=${item.cfdSKU}&id=${item._id}`}
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                            onClick={handleLinkClick}>
                                            <Card style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                                                <div style={{ marginRight: '10px' }}>
                                                    {item.cfdImage && (
                                                        <img
                                                            src={item.cfdImage}
                                                            alt={item.type}
                                                            style={{
                                                                borderRadius: '10%',
                                                                width: '50px',
                                                                height: '50px',
                                                                backgroundSize: 'contain',
                                                                border: '1px solid #0171BB',
                                                                objectFit: 'contain',
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <CardContent style={{ flex: 1 }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <Typography variant="h6" style={{ margin: 0, fontSize: '15px' }}>
                                                            {formatTypeLink(item.typeLink)}
                                                        </Typography>
                                                        <Typography color="textSecondary" variant="body2" style={{ margin: 0 }}>
                                                            <span style={{ color: 'green' }}>{timeAgo(item.submittedAt, 'Inquiry')}</span>
                                                        </Typography>
                                                    </div>
                                                    <Typography variant="body2" style={{ marginTop: '5px', fontSize: '14px' }}>
                                                        Drawing No: {item.cfdSKU}
                                                    </Typography>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                                                        <Typography variant="body2" style={{ margin: 0, color: statusStyles[item.status]?.color }}>
                                                            {statusStyles[item.status]?.icon}
                                                            {` Status: ${item.status}`}
                                                        </Typography>
                                                        <Typography variant="body2" style={{ marginLeft: '10px' }}>
                                                            <Link to={`/v1/portal/csutc/dashboard/inquiries/submitted-inquiries?refer=notifications&whatsnew&drwno=${item.cfdSKU}&uuid=${item.uuid}&id=${item._id}`} style={{ textDecoration: 'none', color: '#2196F3' }}>
                                                                View All Inquiries
                                                            </Link>
                                                        </Typography>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Link>
                                    </Grid>
                                ))
                            ) : (
                                <Grid item xs={12}>
                                <Typography variant="body1" style={{ textAlign: 'left', marginTop: '0px' }}>
                                    {allInquiries.length > 0 ? (  // Check all inquiries length first
                                        <>
                                            No new inquiries submitted.{' '}
                                            <Link to="/v1/portal/csutc/dashboard/inquiries/submitted-inquiries" style={{ textDecoration: 'none' }}>
                                                <Button 
                                                    variant="contained" 
                                                    style={{ 
                                                        backgroundColor: 'white', // Button background color
                                                        color: '#2196F3', // Text color to match link
                                                        padding: '4px 8px', // Small padding for compact size
                                                        fontSize: '14px', // Font size adjustment
                                                        borderRadius: '4px', // Slightly rounded corners
                                                        border: '1px solid #2196F3', // Border color
                                                        marginLeft: '4px', // Space between text and button
                                                    }}>
                                                    Show submitted inquiries
                                                </Button>
                                            </Link>
                                        </>
                                    ) : (
                                        <>
                                            No inquiries submitted at.{' '}
                                            <Link to="/explore-cfd" style={{ textDecoration: 'none' }}>
                                                <Button 
                                                    variant="outlined" 
                                                    style={{ 
                                                        backgroundColor: 'white', // Button background color
                                                        color: '#2196F3', // Text color to match link
                                                        padding: '4px 8px', // Small padding for compact size
                                                        fontSize: '14px', // Font size adjustment
                                                        borderRadius: '4px', // Slightly rounded corners
                                                        border: '1px solid #2196F3', // Border color
                                                        marginLeft: '4px', // Space between text and button
                                                    }}>
                                                    Explore CFD
                                                </Button>
                                            </Link>
                                        </>
                                    )}
                                </Typography>
                            </Grid>                            
                            )}

                            {/* Border line below inquiries */}
                            {inquiries.length > 0 && <Grid item xs={12}><div style={{ borderBottom: '1px solid #ccc', margin: '20px 0' }} /></Grid>}

                            {/* Sets Section */}
                            {latestSets.length > 0 ? (
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" style={{ margin: '2px 0', fontSize: '15px' }}>
                                            CFD Drawings Added
                                        </Typography>
                                    </Grid>
                                    {latestSets.map(item => (
                                        <Grid item xs={12} key={item._id}>
                                            <Link to={`/v1/portal/csutc/dashboard/${item.typeLink}/details/${item.uuid}?refer=notifications&whatsnew&drwno=${item.cfdSKU}&id=${item._id}`}
                                                style={{ textDecoration: 'none', color: 'inherit' }}
                                                onClick={handleLinkClick}>
                                                <Card style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                                                    <div style={{ marginRight: '10px' }}>
                                                        {item.cfdImage && (
                                                            <img
                                                                src={item.cfdImage}
                                                                alt={item.type}
                                                                style={{
                                                                    borderRadius: '10%',
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    backgroundSize: 'contain',
                                                                    border: '1px solid #0171BB',
                                                                    objectFit: 'contain',
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    <CardContent style={{ flex: 1 }}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Typography variant="h6" style={{ margin: 0, fontSize: '15px' }}>
                                                                {formatTypeLink(item.typeLink)}
                                                            </Typography>
                                                            <Typography color="textSecondary" variant="body2" style={{ margin: 0 }}>
                                                                <span style={{ color: 'green' }}>{timeAgo(item.creationDate, 'added')}</span>
                                                            </Typography>
                                                        </div>
                                                        <Typography variant="body2" style={{ marginTop: '5px', fontSize: '14px' }}>
                                                            Drawing No: {item.cfdSKU}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Link>
                                        </Grid>
                                    ))}
                                </>
                            ) : (
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ textAlign: 'center', marginTop: '20px' }}>
                                        No new Drawings loaded this week
                                    </Typography>
                                </Grid>
                            )}

                            {/* Loader and show more button */}
                            {!loading && !loadingMore && latestSets.length < totalSets && (
                                <Grid item xs={12}>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                                        <Button variant="outlined" onClick={handleShowMore}>
                                            Show More
                                        </Button>
                                    </div>
                                </Grid>
                            )}
                            {loadingMore && (
                                <Grid item xs={12}>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                                        <CircularProgress />
                                    </div>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </div>
        </Drawer>
    );
};

export default LatestSetsDrawer;


