// src/components/DidYouKnowLoader/DidYouKnowLoader.js

import React from 'react';
import './DidYouKnowLoader.css'; // Ensure this file exists for styling
import InfoIcon from '@mui/icons-material/Info'; // Import the Info icon from Material-UI

const messages = [
  "We celebrate 15 years of ISO 13485 in 2024",
  "Discover our North American designed and manufactured products!",
  "Explore our cGMP Control for single-use manufacturing",
  "Experience our Class 7 Clean Room—6 Rooms across 4 locations.",
  "We have Class 8 molding capabilities."
];

const DidYouKnowLoader = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = React.useState(
    Math.floor(Math.random() * messages.length)
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => 
        (prevIndex + 1) % messages.length
      );
    }, 3000); // Change the message every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="did-you-know-loader">
      <div className="loader"></div> {/* Add loader animation here */}
      <div className="message-container">
        <InfoIcon className="info-icon" /> {/* Display the icon */}
        <div className="message">{messages[currentMessageIndex]}</div>
      </div>
    </div>
  );
};

export default DidYouKnowLoader;
