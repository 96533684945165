// src/components/Header/Header.js
// src/components/Header/Header.js

import React, { useState, useEffect } from 'react';
import { Avatar, Menu, MenuItem, Tooltip, Badge, Typography, IconButton, CircularProgress } from '@mui/material';
import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ProfileIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import "./Header.css";
import { useTitle } from '../Contexts/TitleContext';
import SearchBar from '../SearchBar/SearchBar';
import LatestSetsDrawer from './LatestSetsDrawer'; 
import { useTheme } from '@mui/material/styles'; 
import Announcement from '../Announcement/Announcement';

const Header = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [avatar, setAvatar] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [totalSets, setTotalSets] = useState(0); 
    const [totalInquiries, setTotalInquiries] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [prevTotalSets, setPrevTotalSets] = useState(0);
    const [prevTotalInquiries, setPrevTotalInquiries] = useState(0);
    const [hasUpdates, setHasUpdates] = useState(false); // New state for update tracking

    const navigate = useNavigate();
    const { title } = useTitle();
    const theme = useTheme(); 

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get('/client/api/dashboard/user-profile-header');
                const { profileImage, avatar, firstName, lastName, email } = response.data;
                setProfileImage(profileImage);
                setAvatar(avatar);
                setFirstName(firstName);
                setLastName(lastName);
                setEmail(email);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        const fetchTotalSets = async () => {
            setIsLoading(true); // Start loading only when fetching starts

            try {
                const response = await axios.get('/client/api/dashboard/latest-notifications-data');
                const newTotalSets = response.data.totalSets;
                const newTotalInquiries = response.data.totalInquiries;

                // Check if there are any updates in the fetched data
                const isUpdated = newTotalSets !== prevTotalSets || newTotalInquiries !== prevTotalInquiries;

                // Update state only if there's been a change
                if (isUpdated) {
                    setTotalSets(newTotalSets);
                    setTotalInquiries(newTotalInquiries);
                    setHasUpdates(true); // Mark that there are updates
                } else {
                    setHasUpdates(false); // No updates, reset
                }

                // Set previous values to current new values
                setPrevTotalSets(newTotalSets);
                setPrevTotalInquiries(newTotalInquiries);

            } catch (error) {
                console.error('Error fetching total sets:', error);
            } finally {
                // Stop loading after data is fetched
                setIsLoading(false);
            }
        };

        // Fetch user data immediately and set interval for fetching updates every 10 seconds
        fetchUserData(); // Fetch user data immediately
        fetchTotalSets(); // Initial fetch for total sets
        const interval = setInterval(fetchTotalSets, 10000); // Fetch updates regularly

        return () => clearInterval(interval); // Clean up the interval on unmount
    }, [prevTotalSets, prevTotalInquiries]); // Dependencies to trigger updates

    const handleLogoutConfirmation = async () => {
        setIsPopupOpen(false);
        try {
            localStorage.setItem('logoutRequested', 'true');
            const response = await axios.get('/client/api/dashboard/logout');
            const token = response.data.uuid;
            localStorage.removeItem('authToken');
            navigate(`/v1/portal/csutc/dashboard/logout/?token=${token}`, { replace: true });
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const handleLogoutClick = () => setIsPopupOpen(true);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleProfileMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleProfileMenuClose = () => setAnchorEl(null);
    const handleSettingsClick = () => {
        handleProfileMenuClose();
        navigate('account-settings/profile');
    };

    const toggleDrawer = () => setDrawerOpen(!drawerOpen);

    return (
        <header className="bg-white text-black p-4 pt-1 shadow-md fixed top-0 left-64 right-0 z-10">
          <Announcement />
            <div className="container header mx-auto flex justify-between items-center">
                <h1 className="title text-xl">{title}</h1>
                <SearchBar className="flex-grow mx-4 search-bar" />
                <div className="flex items-center gap-5">
                    <Tooltip title="What’s New (this week)" placement="bottom">
                        <Badge badgeContent={totalSets + totalInquiries} color="primary" overlap="circular">
                            <div style={{ position: 'relative' }}>
                                {isLoading && hasUpdates && ( // Shows loader only when loading and there are updates
                                    <CircularProgress 
                                        size={24} 
                                        sx={{ 
                                            position: 'absolute', 
                                            top: '50%', 
                                            left: '50%', 
                                            marginTop: '-12px', 
                                            marginLeft: '-12px', 
                                            zIndex: 1 
                                        }} 
                                    />
                                )}
                                <IconButton 
                                    onClick={toggleDrawer} 
                                    className="mr-2"
                                    size="large"
                                    sx={{
                                        padding: '5px',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                            borderRadius: '50%',
                                            padding: '5px'
                                        }
                                    }}
                                >
                                    <NotificationsNoneIcon />
                                </IconButton>
                            </div>
                        </Badge>
                    </Tooltip>
                    <Tooltip
                        title={
                            <div className="bg-black text-white p-2 rounded-md">
                                <Typography variant="body2">{firstName} {lastName}</Typography>
                                <Typography variant="body2">{email}</Typography>
                            </div>
                        }
                        placement="right"
                        arrow
                    >
                        <Avatar
                            alt="User Avatar"
                            src={profileImage || undefined}
                            className="cursor-pointer"
                            onClick={handleProfileMenuOpen}
                        >
                            {!profileImage && avatar}
                        </Avatar>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleProfileMenuClose}
                        MenuListProps={{ onMouseLeave: handleProfileMenuClose }}
                    >
                        <div className="flex items-center p-4 border-b">
                            <Avatar
                                alt="User Avatar"
                                src={profileImage || undefined}
                                className="mr-2"
                            >
                                {!profileImage && avatar}
                            </Avatar>
                            <div>
                                <Typography variant="body1">{firstName} {lastName}</Typography>
                                <Typography variant="body2" color="textSecondary">{email}</Typography>
                            </div>
                        </div>
                        <MenuItem onClick={handleSettingsClick}>
                            <ProfileIcon className="mr-2" />
                            My Profile
                        </MenuItem>
                        <MenuItem onClick={handleLogoutClick}>
                            <LogoutIcon className="mr-2" />
                            Logout
                        </MenuItem>
                    </Menu>
                </div>
            </div>
            <ConfirmationPopup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                onConfirm={handleLogoutConfirmation}
            />
            <LatestSetsDrawer open={drawerOpen} onClose={toggleDrawer} />
        </header>
    );
};

export default Header;
