// src/components/SearchResults/SearchResults.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Grid, Card, CardContent, CardMedia, Typography, CircularProgress, Alert, Box, IconButton } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material'; // Import the Link icon
import useResultClickHandler from '../../utils/resultClickHandler';
import { useTitle } from '../Contexts/TitleContext';

// Recently Viewed
import RecentlyViewedWrapper from '../Contexts/RecentlyViewedContext';

const SearchResults = () => {
    const location = useLocation();
    const handleResultClick = useResultClickHandler();
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('k');
    const { setTitle } = useTitle();
    
    useEffect(() => {
        setTitle(`Search | Total Result: ${results.length}`);  
    }, [results.length, setTitle]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
    
            try {
                const response = await axios.get(`/client/api/dashboard/search?query=${query}`);
                console.log('Response data:', response.data);  // Log the full response data
    
                if (Array.isArray(response.data.results)) {
                    console.log('Search results:', response.data.results);  // Log the results specifically
                    setResults(response.data.results);
                } else {
                    console.log('No results or invalid data structure');
                    setResults([]);
                }
            } catch (err) {
                console.error('Error fetching search results:', err);
                setError('Failed to fetch search results. Please try again later.');
            } finally {
                setLoading(false);
            }
        };
    
        if (query) {
            fetchData();
        }
    }, [query]);

    if (loading) {
        return <CircularProgress style={{ display: 'block', margin: '20px auto' }} />;
    }

    if (error) {
        return <Alert severity="error" style={{ margin: '20px auto', maxWidth: '600px', textAlign: 'center' }}>
            {error}
        </Alert>;
    }

    if (results.length === 0) {
        return <Typography align="center" style={{ margin: '20px auto' }}>No Results Found</Typography>;
    }

    // Function to check if the query matches the card content
    const normalizeText = (text) => {
        return text
            .toLowerCase() // Convert to lowercase
            .replace(/[\s,-]+/g, '') // Remove spaces, commas, hyphens
            .trim(); // Remove extra spaces
    };
    
    const isQueryMatch = (result) => {
        const searchText = normalizeText(query);
    
        const typeMatch = result.type && normalizeText(result.type) === searchText;
        const skuMatch = result.cfdSKU && normalizeText(result.cfdSKU) === searchText;
        const categoriesMatch = Array.isArray(result.categories) &&
            result.categories.some(category => normalizeText(category) === searchText);
    
        return typeMatch || skuMatch || categoriesMatch;
    };

    return (
        <div style={{ padding: '20px' }}>
            {/* Total Results Count */}
            <Typography variant="h5" style={{ marginBottom: '20px', textAlign: 'center' }}>
                {`Total Results: ${results.length}`}
            </Typography>

            {/* Results Grid */}
            <Grid container spacing={3}>
                {results.map((result) => (
                    <Grid item xs={12} sm={6} md={4} key={result.uuid}> {/* Adjusted for 3 cards per row */}
                     <RecentlyViewedWrapper set={result}>
                        <Card
                            onClick={() => handleResultClick(result.typeLink, result.uuid, query)}
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px',
                                height: '100%',
                                position: 'relative', // For positioning the icon
                                border: isQueryMatch(result) ? '2px solid #0171BB' : 'none', // Conditional border for showing border on the query match card
                            }}
                        >
                            {/* Link Icon */}
                            <IconButton
                                style={{
                                    position: 'absolute',
                                    top: '5px',
                                    right: '5px',
                                    zIndex: 1,
                                }}
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent card click event
                                    handleResultClick(result.typeLink, result.uuid, query);
                                }}
                            >
                                <LinkIcon fontSize="small" />
                            </IconButton>

                            {/* Circular Image */}
                            {/* Circular Image */}
                            <CardMedia
                                component="img"
                                alt={result.type}
                                image={result.cfdImage}
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    borderRadius: '0',
                                    objectFit: 'cover', // Ensures image covers the circle fully
                                    border: '1px solid #cccccc', // Light border
                                    padding: '2px', // Optional padding for a cleaner look
                                }}
                            />

                            {/* Card Content */}
                            <CardContent style={{ flex: 1 }}>
                                {/* Inline display for type and SKU */}
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Typography variant="h6">{result.type}</Typography>
                                    <Typography variant="body2" color="textSecondary">{result.cfdSKU}</Typography>
                                </Box>

                                {/* Categories below */}
                                <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
                                    {Array.isArray(result.categories) ? result.categories.join(', ') : result.categories}
                                </Typography>
                            </CardContent>
                        </Card>
                        </RecentlyViewedWrapper>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default SearchResults;