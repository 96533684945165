// src/components/Filters/ImagePopup.js
import React, { useState } from 'react';
import { Modal, IconButton, Button } from '@mui/material';
import { ZoomIn as ZoomInIcon, ZoomOut as ZoomOutIcon, Close as CloseIcon } from '@mui/icons-material';
import { Icon } from '@mui/material';
import BusinessIcon from '@mui/icons-material/BusinessOutlined';
import LabelIcon from '@mui/icons-material/LabelOutlined';
import CategoryIcon from '@mui/icons-material/CategoryOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import BackpackOutlinedIcon from '@mui/icons-material/BackpackOutlined';
import FormatSizeOutlinedIcon from '@mui/icons-material/FormatSizeOutlined';
import FlipCameraAndroidOutlinedIcon from '@mui/icons-material/FlipCameraAndroidOutlined';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import commonSetFields from '../../../data/commonSetField.json';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

// Recently Viewed
import RecentlyViewedWrapper from '../../Contexts/RecentlyViewedContext';

// Wishlist
import WishlistButton from '../../Contexts/WishlistButton';

// Import CategorySlider component
import CategorySlider from '../Filters/CategorySlider';

const useStyles = makeStyles((theme) => ({
  modalContent: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '90vw',
    maxHeight: '90vh',
    margin: '0 auto',
    padding: theme.spacing(2),
    backgroundColor: 'white',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(2),
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
  },
  imageSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
    transition: 'width 0.3s ease', // Smooth transition for width changes
  },
  sideImagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    paddingLeft: '10px',
    paddingTop: '10px',
    rowGap: theme.spacing(1),
    paddingRight: theme.spacing(2),
    transition: 'width 0.3s ease', // Smooth transition for width changes
  },

  hidden: {
    width: '0%',
    visibility: 'hidden',
    opacity: 0,
  },
  fullWidth: {
    minWidth: '100%',
    maxWidth: '100%',
  },


  imageWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '550px',
    overflow: 'hidden',
  },
  zoomControls: {
    position: 'absolute',
    left: '50%',
    top: '60%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    gap: theme.spacing(2),
    zIndex: 10,
  },
  dataSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflowY: 'auto',
    maxHeight: '300px',
    paddingLeft: theme.spacing(2),
  },

  sideImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    padding: theme.spacing(1),
    borderRadius: '8px',
    border: '2px solid #dcdcdc',
    transition: 'transform 0.2s, border-color 0.2s',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      borderColor: '#0171BB',
      overFlow: 'visible'
    },
  },
  sideImage: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '8px',
    marginBottom: theme.spacing(1),
  },
  showMoreButton: {
    marginTop: theme.spacing(2),
    alignSelf: 'center',
    borderColor: '#0171BB',
    color: '#0171BB',
    '&:hover': {
      backgroundColor: '#ebf8ff',
    },
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  makeInquiryButton: {
    backgroundColor: '#0171BB',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#005f9e',
    },
  },
  viewDetailsButton: {
    border: '2px solid #0171BB',
    color: '#0171BB',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#ebf8ff',
    },
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: 'white',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 9999,  // Ensure it's on top of other elements
    '&:hover': {
      backgroundColor: '#f0f0f0',  // Light background color on hover
      boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',  // Slightly stronger shadow on hover
      cursor: 'pointer',  // Change cursor to pointer to indicate it's clickable
    },
  },  
  // Responsive Styles
  '@media (max-width: 768px)': {
    modalContent: {
      flexDirection: 'column', // Stack columns for mobile view
      maxWidth: '90vw',
    },
    imageSection: {
      maxWidth: '100%',
      marginRight: 0,
    },
    sideImagesContainer: {
      marginTop: theme.spacing(2),
      width: '100%', // Side images take full width on small screens
    },
  },
}));

const ImagePopup = ({
  isImagePopupOpen,
  zoomLevel,
  handleCloseImagePopup,
  handleZoomIn,
  handleZoomOut,
  selectedSet,
  setSelectedSet,
  setZoomLevel,
  handleOpenPopup,
  categoryLink,
  allSets,
}) => {
  const classes = useStyles();
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [visibleImages, setVisibleImages] = useState(5);
  const [isZoomedIn, setIsZoomedIn] = useState(false);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      setOffset((prev) => ({
        x: prev.x + e.movementX,
        y: prev.y + e.movementY,
      }));
    }
  };

  const handleZoomInClick = () => {
    handleZoomIn();
    setIsZoomedIn(true);
  };

  const handleZoomOutAndReset = () => {
    handleZoomOut();
    setOffset({ x: 0, y: 0 });
    setIsZoomedIn(false);
  };

  const iconMapping = {
    company: BusinessIcon,
    cfdSKU: LabelIcon,
    gammaTreatment: InfoIcon,
    productPerCase: CategoryIcon,
    packaging: BackpackOutlinedIcon,
    engineerName: EngineeringOutlinedIcon,
    size: FormatSizeOutlinedIcon,
    rev: FlipCameraAndroidOutlinedIcon,
  };

  if (!selectedSet || !allSets) return null;

  const filteredSets = allSets.filter(set => set.uuid !== selectedSet.uuid);

  const handleSideImageClick = (set) => {
    setSelectedSet(set);
    setZoomLevel(1);
    setOffset({ x: 0, y: 0 });
  };

  const hasSideImages = filteredSets.length > 0;
  
  return (
    <Modal open={isImagePopupOpen} onClose={handleCloseImagePopup} className="flex items-center justify-center">
      <div className={classes.modalContent}>
           {/* Close Icon */}
        <IconButton onClick={handleCloseImagePopup} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
        <div
          className={`${classes.imageSection} ${
            !hasSideImages || isZoomedIn ? classes.fullWidth : ''
          }`}
        >
          {/* Image Section */}
          <div
            className={classes.imageWrapper}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            <img
              src={selectedSet?.cfdImage}
              alt="Zoomed view"
              style={{
                transform: `scale(${zoomLevel}) translate(${offset.x}px, ${offset.y}px)`,
              }}
              className={classes.image}
            />
          </div>

          {/* Zoom Controls */}
          <div className={classes.zoomControls}>
          <Button variant="outlined" onClick={handleZoomInClick}>
              <ZoomInIcon />
            </Button>
            <Button variant="outlined" onClick={handleZoomOutAndReset}>
              <ZoomOutIcon />
            </Button>
          </div>

          {/* Data Section */}
          <div className={classes.dataSection}>
           <h2 className="text-xl font-bold mb-2">{selectedSet?.categories}</h2>
            <p className="text-gray-700 text-x font-bold mb-2"><DatasetOutlinedIcon className={classes.icon} />Sets Category: {selectedSet?.type}</p>
            {selectedSet ? (
              commonSetFields?.fields.map((field, index) => {
                const IconComponent = iconMapping[field.field] || Icon;

                if (field.field === 'categories') {
                  return (
                    <p key={index} className="text-gray-700 mb-2 flex items-center">
                      <CategoryIcon className={classes.icon} />
                      <span className="font-500">{field.label}:</span>
                      <CategorySlider
                        categories={selectedSet[field.field]?.flatMap((category) =>
                          category.split(',').map((cat) => cat.trim())
                        ) || []}
                      />
                    </p>
                  );
                }

                return (
                  <p key={index} className="text-gray-700 mb-2 flex items-center">
                    {IconComponent && <IconComponent className={classes.icon} />}
                    {field.label}:&nbsp;
                    <strong>{selectedSet[field.field] || 'N/A'}</strong>
                  </p>
                );
              })
            ) : (
              <p>No data available</p>
            )}
          </div>

          {/* Button Group */}
          <div className={classes.buttonGroup}>
            <Button
              variant="contained"
              className={classes.makeInquiryButton}
              onClick={() => handleOpenPopup(selectedSet?.type, selectedSet?.uuid)}
            >
              Make Inquiry
            </Button>
            <RecentlyViewedWrapper set={selectedSet}>
            <Link to={`/v1/portal/csutc/dashboard/${categoryLink}/details/${selectedSet?.uuid}`}>
              <Button variant="contained" className={classes.viewDetailsButton}>
                View Details
              </Button>
            </Link>
            </RecentlyViewedWrapper>
              {/* Wishlist Button */}
              <WishlistButton set={selectedSet} />
          </div>
        </div>

        <div
          className={`${classes.sideImagesContainer} ${
            !hasSideImages || isZoomedIn ? classes.hidden : ''
          }`}
        >
          <h5>Related Drawings</h5>
          {/* Side Images Container */}
          {filteredSets.slice(0, visibleImages).map((set) => (
            <div
              key={set.uuid}
              className={`${classes.sideImageContainer} ${set.uuid === selectedSet?.uuid ? classes.activeImage : ''}`}
              onClick={() => handleSideImageClick(set)}
            >
              <img
                src={set.cfdImage}
                alt={set.type}
                className={classes.sideImage}
              />
              <p className={classes.skuText}>{set.cfdSKU}</p>
            </div>
          ))}
          {filteredSets.length > visibleImages && (
            <Button
              variant="outlined"
              className={classes.showMoreButton}
              onClick={() => setVisibleImages((prev) => prev + 5)}
            >
              Show More
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ImagePopup;
