// src/components/BagSets/BagSetDetails/BagSetDetails.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import categories from '../../../../data/categories.json'; // Import the JSON data
import { useTitle } from '../../../Contexts/TitleContext';
import { Icon } from '@mui/material';
import commonSetFields from '../../../../data/commonSetField.json';
import BusinessIcon from '@mui/icons-material/BusinessOutlined';
import LabelIcon from '@mui/icons-material/LabelOutlined';
import CategoryIcon from '@mui/icons-material/CategoryOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import BackpackOutlinedIcon from '@mui/icons-material/BackpackOutlined';
import FormatSizeOutlinedIcon from '@mui/icons-material/FormatSizeOutlined';
import FlipCameraAndroidOutlinedIcon from '@mui/icons-material/FlipCameraAndroidOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import getIconComponent from '../../../../utils/iconUtility'; // Adjust path as needed
import InquiryIcon from '@mui/icons-material/HelpOutline';

import EnquiryPopup from '../../../Common/InquiryPopup';
import RelatedItems from '../../../Common/RelatedItems';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Badge,
  Divider,
  Paper,
} from '@mui/material';

import ImagePopup from '../../../../hooks/ImagePopup';

import Copyright from '../../../Common/Copyright'; 

import CategoryButtons from '../../../Common/CategoryButtons'; 

import TabComponent from '../../../../hooks/TabsComponent'; 
import useStylesSetDetails from '../../../Common/useStylesSetDetails'; 

// Import CategorySlider component
import CategorySlider from '../../Filters/CategorySliderDetails'; // Import CategorySlider
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';

// Info label
import InfoLabel from '../../../Common/InfoLabelDetails';

const BagSetDetails = () => {

  const classes = useStylesSetDetails();
  const [setDetails, setSetDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const location = useLocation();
  const [categoryDetail, setCategoryDetail] = useState('');
  const { setTitle } = useTitle();
  const navigate = useNavigate();

  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedSetType, setSelectedSetType] = useState('');
  const [selectedSetSKU, setSelectedSetSKU] = useState('');
  const [selectedUUID, setSelectedUUID] = useState('');
  const [selectedSet, setSelectedSet] = useState(null);  

  const [imagePopupOpen, setImagePopupOpen] = useState(false);
  const [popupImage, setPopupImage] = useState('');

  // Extract UUID from the URL path
  const pathParts = location.pathname.split('/');
  const uuid = pathParts[pathParts.length - 1]; // Assuming UUID is the last part of the path
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    if (setDetails && setDetails.cfdSKU) {
      setTitle(`Bag Set Details | Drawing No: ${setDetails.cfdSKU}`);
    }
  }, [setDetails, setTitle]);  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/client/api/dashboard/sidebar/bag-sets/${uuid}`);
        setSetDetails(response.data);
      } catch (error) {
        console.error('Error fetching bag set details:', error);
        setError('Failed to fetch bag set details.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [uuid]);

  useEffect(() => {
    if (categories.length > 0) {
      const selectedCategory = categories[2]; // Select the desired item
      setCategoryDetail(selectedCategory.detail);
      setSelectedCategory(selectedCategory);
    }
  }, []);
  
  

  const iconMapping = {
    company: BusinessIcon,
    cfdSKU: LabelIcon,
    gammaTreatment: InfoIcon,
    productPerCase: CategoryIcon,
    packaging: BackpackOutlinedIcon,
    engineerName: EngineeringOutlinedIcon,
    size: FormatSizeOutlinedIcon,
    rev: FlipCameraAndroidOutlinedIcon
  };


  const handleGoBackClick = () => {
      navigate(-1);
  };

  
  const handleOpenPopup = (setType, uuid) => {
    const selectedSet = setDetails;
    setSelectedSetType(setType);
    setSelectedSetSKU(selectedSet?.cfdSKU || '');
    setSelectedUUID(uuid);
    setSelectedSet(selectedSet); 
    setPopupOpen(true);
  };
  

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleOpenImagePopup = (image) => {
    setPopupImage(image);
    setImagePopupOpen(true);
  };

  const handleCloseImagePopup = () => {
    setImagePopupOpen(false);
  };


  if (loading) {
    return <div className="text-center py-4">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 py-4">{error}</div>;
  }

  if (!setDetails) {
    return <div className="text-center py-4">No details found.</div>;
  }

  return (
    <div className="p-6" id="back">
       <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={4} sm={4} md={3}>
          <Button
            startIcon={getIconComponent('ArrowBack')}
            onClick={handleGoBackClick}
            variant="contained"
            sx={{ bgcolor: '#9e9e9e', '&:hover': { bgcolor: '#757575' } }}
          >
            Go Back
          </Button>
        </Grid>
        <Grid item xs={8} sm={8} md={6} textAlign="center">
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
           Customer Facing Drawing for {setDetails.cfdSKU}
          </Typography>
        </Grid>
        <Grid item xs={0} sm={0} md={3} />
      </Grid>

      <Card className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className="relative">
          <div className="relative w-full h-full pb-10 overflow-visible">
              {setDetails.cfdImage ? (
                <>
                  <CardMedia
                    className={`${classes.media} cursor-pointer`}
                    image={setDetails.cfdImage}
                    title={`${setDetails.type} image`}
                    onClick={() => handleOpenImagePopup(setDetails.cfdImage)} // Handle the image click
                  />
                  <Button
                  className="absolute bottom-10 left-0 w-full flex items-center justify-center text-white bg-black bg-opacity-50 hover:bg-opacity-75 transition-opacity"
                  onClick={() => handleOpenImagePopup(setDetails.cfdImage)}
                >
                  Click to View
                </Button>
                </>
              ) : (
                <div className="w-full h-64 bg-gray-200"></div>
              )}
                  {/* Use the InfoLabel component Bottom Info */}
           <InfoLabel dynamicValue={setDetails.cfdSKU}/>
            </div>
            {/* <Badge badgeContent={categoryDetail} color="secondary" className={classes.badge} /> */}
          </Grid>

          <Grid item xs={12} md={6}>
            <CardContent>
              <div className="p-4">
              {commonSetFields.fields.map((field, index) => {
                if (field.field === 'categories') {
                 return (
                  <div key={index}>
                      <h2 className={`text-primary mb-2 text-xl font-bold capitalize`}>
                       {String(setDetails.categories || '')} {/* Ensure it's a string */}
                      </h2>
                      </div>
                        );
                    }
                })}
                {/* {commonSetFields.fields.map((field, index) => {
                  const IconComponent = iconMapping[field.field] || Icon;
                  return (
                    <p key={index} className="text-gray-700 mb-2 flex items-center">
                      {IconComponent && <IconComponent className={classes.icon} />}
                      &nbsp;{field.label}:&nbsp;<strong>{setDetails[field.field] || 'N/A'}</strong>
                    </p>
                  );
                })} */}
                 <p className="text-gray-700 text-x flex items-center mb-2 gap-1"><DatasetOutlinedIcon className={classes.icon} />Sets Category: <strong className='font-bold'>{setDetails?.type}</strong></p>
                {commonSetFields.fields.map((field, index) => {
                const IconComponent = iconMapping[field.field] || Icon;

              // Check if the field is 'categories' and render it differently
              if (field.field === 'categories') {
                return (
                  <p key={index} className="text-gray-700 mb-2 flex items-center gap-1">
                    <CategoryIcon className={classes.icon} />
                    <span className="font-500">{field.label}:</span>
                    
                    <CategorySlider
                      categories={setDetails[field.field]?.flatMap(category => category.split(',').map(cat => cat.trim())) || []}
                    />
                  </p>
                );
              }

              // For other fields, use the default rendering
              return (
                <p key={index} className="text-gray-700 mb-2 flex items-center gap-1">
                  {IconComponent && <IconComponent className={classes.icon} />}
                  {field.label}:&nbsp;
                  <strong>{setDetails[field.field] || 'N/A'}</strong>
                </p>
              );
            })}
              </div>
              {/* {setDetails.categories && (
                <div className="mt-2 p-4">
                 <CollectionsOutlinedIcon />
                    &nbsp;Categories:
                  <ul className="list-disc pl-5">
                    {setDetails.categories.map((category, index) => (
                      <li key={index} className="text-gray-600">{category}</li>
                    ))}
                  </ul>
                </div>
              )} */}
              <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<InquiryIcon />} // Example icon
              onClick={() => handleOpenPopup(setDetails.type, uuid)}
              sx={{ width: '100%' }} // Set width to 100%
              >
                Inquiry Now
              </Button>
            </CardContent>
          </Grid>
        </Grid>
      </Card>

       {/* Integrate TabComponent here */}
      <div className="mt-6">
     <Typography variant="h6" className={classes.heading}>
        Drawing Detail
      </Typography>
        <TabComponent
          description={setDetails.cfdDescription} 
          specification={setDetails.cfdSpecification || 'Specification data not available.'} 
        />
      </div>
      <div className="mb-12 text-center">
      <Copyright />
      </div>
      <Paper className={classes.container}>
      <Typography variant="h6" className={classes.heading}>
        Interested in {setDetails.cfdSKU} Drawing
      </Typography>
      <Button
        variant="contained"
        className={classes.button}
        startIcon={<InquiryIcon />} // Example icon
        onClick={() => handleOpenPopup(setDetails.type, uuid)}
      >
        Inquiry Now
      </Button>
    </Paper>
      <div className="mt-6">
      <RelatedItems 
        currentUUID={uuid} 
        apiEndpoint={`/client/api/dashboard/sidebar/bag-sets`} 
        title={`Recommended ${selectedCategory?.name} Customer Facing Drawings`}
        categoryLink={selectedCategory}
      />
      </div>
      <div className="mt-6 mb-6">
        <Divider /> {/* Added Divider */}
      </div>

     <CategoryButtons excludedCategoryLink={selectedCategory?.link} />

     <EnquiryPopup
      open={popupOpen}
      handleClose={handleClosePopup}
      selectedSet={selectedSet} // Already correctly passed
      setSelectedSetType={selectedSetType} // Rename from setType to selectedSetType
      selectedSetSKU={selectedSetSKU} // Rename from setSKU to selectedSetSKU
      />
      
      <ImagePopup
        open={imagePopupOpen}
        onClose={handleCloseImagePopup}
        image={popupImage}
      />
    </div>
  );
};

export default BagSetDetails;
