// src/components/Announcement/Announcement.js

import React from 'react';

const Announcement = () => {
    return (
        <div className="bg-white text-announcement-gray p-1 mb-4 border-b-2 border-[#cccccc] flex items-center justify-between">
            <p className="font-semibold text-center flex-1 lg:text-left">
            World’s First Custom Library with Over 1000+ CFD Drawings!
            </p>
            <div className="flex space-x-4"> {/* Flex for logo layout */}
                {/* Logo 1 */}
                <div className="border border-gray-400 w-16 flex items-center justify-center">
                    <img
                        src="https://foxxbioprocess.com/cdn/shop/files/USA-01_1850x_74cc7e4e-f27d-4279-9c3f-120cae584ba3_1850x.webp?v=1725545492"
                        alt="USA Logo"
                        className="h-full w-full object-contain" // Full width, medium height
                    />
                </div>
                {/* Logo 2 */}
                <div className="border border-gray-400 w-16 flex items-center justify-center">
                    <img
                        src="https://foxxbioprocess.com/cdn/shop/files/13485-01_1850x_3d2c1410-6f89-4ad1-919b-8d711def952d_1850x.webp?v=1725545527"
                        alt="Another Logo"
                        className="h-full w-full object-contain" // Full width, medium height
                    />
                </div>
            </div>
        </div>
    );
};

export default Announcement;
