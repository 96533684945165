export const categoryLinks = [
    { id: 'Tubing', link: 'tubing-sets', name: 'Tubing Sets', image: 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1722642830/bioprocess-web-custom-sut-area-cfd-diagrams-tubing-sets_free_size_vvdwcv.png' },
    { id: 'Filter', link: 'filter-sets', name: 'Filter Sets', image: 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1722642657/bioprocess-web-custom-sut-area-cfd-diagrams-filter-sets_free_size_k8yclu.png' },
    { id: 'Bag', link: 'bag-sets', name: 'Bag Sets', image: 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1722642328/bioprocess-web-custom-sut-area-cfd-diagrams-bag-sets_free_size_kcuxyt.png' },
    { id: 'Needle', link: 'needle-sets', name: 'Needle Sets', image: 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1722642770/bioprocess-web-custom-sut-area-cfd-diagrams-needle-sets_free_size_jqbdda.png' },
    { id: 'Cap', link: 'cap-assemblies-sets', name: 'Cap Assemblies Sets', image: 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1722642475/bioprocess-web-custom-sut-area-cfd-diagrams-cap-assemblies-sets_free_size_j2ftix.png' },
    { id: 'Bottle', link: 'bottle-sets', name: 'Bottle Sets', image: 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1740070409/Bottle_sets-home-icon_yf4ako.png' },
    { id: 'BottleM', link: 'bottle-manifold-sets', name: 'Bottle Manifold Sets', image: 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1740070507/Bottle_manifold-home-icon_hjiit9.png' },
    { id: 'Carboy', link: 'carboy-sets', name: 'Carboy Sets', image: 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1740070560/Carboy_sets-home-icon_lq1qbh.png' },
    { id: 'CarboyM', link: 'carboy-manifold-sets', name: 'Carboy Manifold Sets', image: 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1740070623/Carboy_manifold-sets-icon_rtvjv4.png' },
    { id: 'Flask', link: 'flask-sets', name: 'Flask Sets', image: 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1722642704/bioprocess-web-custom-sut-area-cfd-diagrams-flask-sets_free_size_eyhs3s.png' },
    { id: 'Centrifuge', link: 'centrifuge-sets', name: 'Centrifuge Sets', image: 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1722642548/bioprocess-web-custom-sut-area-cfd-diagrams-centrifuge-sets_free_size_fqe59i.png' },
    { id: 'Custom', link: 'custom-sets', name: 'Custom Sets', image: 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1722642604/bioprocess-web-custom-sut-area-cfd-diagrams-custom-sets_free_size_v6jrov.png' },
    { id: 'Welded', link: 'welded-sets', name: 'Welded Sets', image: 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1722642891/bioprocess-web-custom-sut-area-cfd-diagrams-welded-sets_free_size_zy2sah.png' },
    // { id: '3d', link: '3d-printed-sets', name: '3D Printed Sets', image: '' },
    { id: 'Mixing', link: 'mixing-sets', name: 'Mixing Sets', image: 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1740069659/Mixer_set-home-icon_isvtxt.png' },
    { id: 'Jumper', link: 'jumper-sets', name: 'Jumper Sets', image: 'https://res.cloudinary.com/dtsg4pipk/image/upload/v1740069549/jumper-sets-home-icon_w6h2wt.png' }
  ];
  