// src/components/SearchBar/SearchBar.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { TextField, List, ListItem, Avatar, Typography, Paper, CircularProgress, Button, InputAdornment, IconButton } from '@mui/material';
import { Search as SearchIcon, Link as LinkIcon, Clear as ClearIcon, History as HistoryIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import './SearchBar.css';
// Recently Viewed
import RecentlyViewedWrapper from '../Contexts/RecentlyViewedContext';

const SearchBar = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [showResults, setShowResults] = useState(false); // Initially set to false
    const [loading, setLoading] = useState(false);
    const [totalResults, setTotalResults] = useState(0);
    const [debouncedQuery, setDebouncedQuery] = useState('');

    // Recent search
    const [recentSearches, setRecentSearches] = useState([]);
    const [isFocused, setIsFocused] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const searchRef = useRef(null);

    // Extract the query parameter from the URL
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const queryFromUrl = queryParams.get('k');
        if (queryFromUrl) {
            setQuery(queryFromUrl);
        }
    }, [location.search]);

    // Fetch recent searches from the API
    useEffect(() => {
        const fetchRecentSearches = async () => {
            try {
                const response = await axios.get(`/client/api/dashboard/search/recentsearches`);
                setRecentSearches(response.data);
            } catch (err) {
                console.error('Error fetching recent searches:', err);
            }
        };
        fetchRecentSearches();
    }, []);

    // Debounced search function
    useEffect(() => {
        if (debouncedQuery.length > 0) {
            setLoading(true);
            axios
                .get(`/client/api/dashboard/search?query=${debouncedQuery}`)
                .then((res) => {
                    setResults(res.data.results);
                    setTotalResults(res.data.totalResults);
                    setLoading(false);
                })
                .catch((err) => {
                    console.error('Error fetching search results:', err);
                    setLoading(false);
                });
        } else {
            setResults([]);
            setTotalResults(0);
        }
    }, [debouncedQuery]);

    // Handle query changes and set debounced query
    const handleSearch = (e) => {
        setQuery(e.target.value);
    };

    // Debounce the query input
    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedQuery(query);
        }, 500);
        return () => clearTimeout(timeout);
    }, [query]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && query.length > 0) {
            setShowResults(false); // Close the popup
            navigate(`/v1/portal/csutc/dashboard/search/s?k=${query}`);
        }
    };

    const handleClickOutside = (e) => {
        if (searchRef.current && !searchRef.current.contains(e.target)) {
            setShowResults(false);  // Close search results
            setIsFocused(false);   // Ensure recent searches also close
        }
    };
    
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const handleItemClick = (typeLink, uuid, clickedQuery) => {
        setQuery(clickedQuery);
        setDebouncedQuery(clickedQuery);
        setShowResults(false);
    
        setTimeout(() => {
            navigate(`/v1/portal/csutc/dashboard/${typeLink}/details/${uuid}`);
        }, 300); 
    };

    const handleViewAllClick = () => {
        setShowResults(false); // Close the popup
        navigate(`/v1/portal/csutc/dashboard/search/s?k=${query}`);
    };

    const handleFocus = () => {
        setIsFocused(true);
        setShowResults(true); // Show results when the search bar is focused
    };

    const handleBlur = () => {
        setTimeout(() => {
            if (!searchRef.current.contains(document.activeElement)) {
                setShowResults(false);
                setIsFocused(false);
            }
        }, 150);  // Small delay to allow clicks on search results
    };
    
    
    const handleRecentSearchClick = (keyword) => {
        setQuery(keyword);
        setDebouncedQuery(keyword); 
        setShowResults(false);
    
        setTimeout(() => {
            navigate(`/v1/portal/csutc/dashboard/search/s?k=${keyword}`);
        }, 300); 
    };
    
    const renderResults = () => {
        if (!showResults) return null;

        return (
            <Paper className="search-results" onClick={(e) => e.stopPropagation()}>
                {results.length > 0 ? (
                    <>
                        <Typography variant="body2" align="center" style={{ marginBottom: '10px' }}>
                            {totalResults} result{totalResults !== 1 ? 's' : ''} found
                        </Typography>
                        <List>
                            {loading ? (
                                <div className="loading-placeholder">
                                    <CircularProgress size={24} />
                                    <Typography variant="body2" align="center" style={{ marginTop: '10px' }}>
                                        Searching...
                                    </Typography>
                                </div>
                            ) : (
                                results.slice(0, 5).map((set) => (
                                    <RecentlyViewedWrapper set={set}>
                                    <ListItem
                                        key={set.uuid}
                                        button
                                        onClick={() => handleItemClick(set.typeLink, set.uuid, set.cfdSKU)} // Pass the clicked result's SKU
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <SearchIcon style={{ color: '#888', marginRight: '10px' }} />
                                            <Avatar
                                                src={set.cfdImage}
                                                alt={set.type}
                                                sx={{ width: 40, height: 40, objectFit: 'contain' }}
                                            />
                                            <div style={{ marginLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="body1" style={{ display: 'inline' }}>
                                                    {set.type} - <span style={{ color: '#666' }}>{set.cfdSKU}</span>
                                                </Typography>
                                                <Typography variant="caption" display="inline" style={{ color: '#888', fontSize: '0.75rem', marginTop: '4px' }}>
                                                    {set.categories}
                                                </Typography>
                                            </div>
                                        </div>
                                        <LinkIcon style={{ color: '#888', marginLeft: '10px' }} />
                                    </ListItem>
                                    </RecentlyViewedWrapper>
                                ))
                            )}
                        </List>
                        {results.length > 5 && (
                            <Button variant="outlined" fullWidth onClick={handleViewAllClick} style={{ marginTop: '10px' }}>
                                View All
                            </Button>
                        )}
                    </>
                ) : null}
            </Paper>
        );
    };

    // delete recent search history one by one
    const handleDeleteRecentSearch = async (searchId, recentsearches) => {
        try {
            await axios.delete(`/client/api/dashboard/search/${recentsearches}/${searchId}`);
            setRecentSearches(recentSearches.filter(search => search._id !== searchId));
        } catch (err) {
            console.error('Error deleting recent search:', err);
        }
    };


    const handleClearAllRecentSearches = async (recentsearches) => {
        try {
            await axios.delete(`/client/api/dashboard/search/${recentsearches}/all`);
            setRecentSearches([]); // Clear all from the UI state
        } catch (err) {
            console.error('Error deleting all recent searches:', err);
        }
    };
    
    
    
    const renderRecentSearches = () => {
        if (recentSearches.length === 0 || query.length > 0 || !isFocused) return null; // Only show when focused and query is empty
    
        return (
            <div className="recent-searches">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                        Recent Searches
                    </Typography>
                    {recentSearches.length > 0 && (
                        <IconButton onClick={() => handleClearAllRecentSearches('recentsearches')}>
                            <DeleteIcon style={{ color: '#d32f2f' }} />
                        </IconButton>
                    )}
                </div>
                <List>
                    {recentSearches.map((search, index) => (
                        <ListItem
                            key={index}
                            button
                            onClick={(event) => handleRecentSearchClick(search.keyword, event)}
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <HistoryIcon style={{ color: '#888', marginRight: '10px' }} />
                                <Typography variant="body1">{search.keyword}</Typography>
                            </div>
                            <ClearIcon 
                                style={{ color: '#888', cursor: 'pointer' }} 
                                onClick={(event) => {
                                    event.stopPropagation(); // Prevent the ListItem onClick from firing
                                    handleDeleteRecentSearch(search._id, 'recentsearches'); 
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </div>
        );
    };
    

    return (
        <div className="search-bar" ref={searchRef}>
            <TextField
                label="Search Sets"
                variant="outlined"
                fullWidth
                value={query}
                onChange={handleSearch}
                onKeyPress={handleKeyPress}
                onFocus={handleFocus} // Show results when focused
                onBlur={handleBlur}    
                placeholder="Search by SKU, type, sets"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment: query && (
                        <InputAdornment position="end">
                            <ClearIcon onClick={() => setQuery('')} style={{ cursor: 'pointer' }} />
                        </InputAdornment>
                    ),
                }}
                inputProps={{
                    autoComplete: "off", 
                    autoCorrect: "off",  
                    spellCheck: "false",
                }}
            />

            {renderResults()}
            {renderRecentSearches()} {/* Render recent searches below the search bar */}
        </div>
    );
};

export default SearchBar;