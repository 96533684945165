// src/components/CentrifugeSets/CentrifugeSets.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CentrifugeSetSkeletonLoader from '../../Skeleton/commonSetSkeletonLoader';
import ImageSkeletonLoader from '../../Common/ImageSkeletonLoader';
import categories from '../../../data/categories.json';
import commonSetFields from '../../../data/commonSetField.json';
import { useTitle } from '../../Contexts/TitleContext';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Icon } from '@mui/material';
import BusinessIcon from '@mui/icons-material/BusinessOutlined';
import LabelIcon from '@mui/icons-material/LabelOutlined';
import CategoryIcon from '@mui/icons-material/CategoryOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import BackpackOutlinedIcon from '@mui/icons-material/BackpackOutlined';
import FormatSizeOutlinedIcon from '@mui/icons-material/FormatSizeOutlined';
import FlipCameraAndroidOutlinedIcon from '@mui/icons-material/FlipCameraAndroidOutlined';
import EnquiryPopup from '../../Common/InquiryPopup';

import BannerDisplay from '../../Common/CategoryBannerDisplay';

// Image Zoom in Zoom Out

import ZoomInIcon from '@mui/icons-material/ZoomIn';


// Filter

import FilterSidebar from '../Filters/FilterSidebar';

// Import the custom hook
import useFilterAndSort from '../Filters/useFilterAndSort';

// For meta og title 
import { Helmet } from 'react-helmet';
import useFavicon from '../../../hooks/useFavicon';
import favicon from '../../../assets/images/favicon.png'; // Import favicon


// Import CategorySlider component
import CategorySlider from '../Filters/CategorySlider'; // Import CategorySlider

// Common Filter and Image Popup
import CommonFilters from '../Filters/CommonFilters'; // Import the common filter component
import ImagePopup from '../Filters/ImagePopup'; // Import the image popup component

// Applied Filter Block
import AppliedFilters from '../Filters/AppliedFilters';  

// Recently Viewed
import RecentlyViewedWrapper from '../../Contexts/RecentlyViewedContext';

// Wishlist
import WishlistButton from '../../Contexts/WishlistButton';

// Info label
import InfoLabel from '../../Common/InfoLabel';

 // use for filter local storage
const storageKey = 'centrifugeSetsFilters';


const useStyles = makeStyles((theme) => ({
  card: {
    border: '2px solid #3b82f6', // Blue border
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2), // Adds space between the buttons
    marginTop: theme.spacing(2), // Adds some space above the buttons
    marginBottom: theme.spacing(2), // Adds some space above the buttons
  },
  viewDetailsButton: {
    border: '2px solid #3b82f6', // Blue border
    color: '#3b82f6', // Blue text color
    backgroundColor: 'transparent', // No background color
    '&:hover': {
      backgroundColor: '#ebf8ff', // Light blue background on hover
    },
  },
  makeInquiryButton: {
    backgroundColor: '#0171BB', // Set background to blue
    color: '#fff', // White text color
    '&:hover': {
      backgroundColor: '#005f9e', // Darker blue on hover
    },
  },
}));

const iconMapping = {
  company: BusinessIcon,
  cfdSKU: LabelIcon,
  gammaTreatment: InfoIcon,
  productPerCase: CategoryIcon,
  packaging: BackpackOutlinedIcon,
  engineerName: EngineeringOutlinedIcon,
  size: FormatSizeOutlinedIcon,
  rev: FlipCameraAndroidOutlinedIcon
};

const CentrifugeSets = () => {
  useFavicon(favicon); // Use imported favicon
  const [centrifugeSets, setCentrifugeSets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [imageLoading, setImageLoading] = useState({});
  const [failedImages, setFailedImages] = useState({});
  const [categoryName, setCategoryName] = useState('');
  const [categoryLink, setCategoryLink] = useState('');
  const [skeletonCount, setSkeletonCount] = useState(6);
  const [imagesLoaded, setImagesLoaded] = useState({});
  const [sortOrder, setSortOrder] = useState('new-to-old'); 
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedSetType, setSelectedSetType] = useState('');
  const [selectedSetSKU, setSelectedSetSKU] = useState('');
  const [selectedUUID, setSelectedUUID] = useState('');
  const classes = useStyles();
  const { setTitle } = useTitle();
  const [selectedSet, setSelectedSet] = useState(null);  

    // Image Popup and zoom
    const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [zoomLevel, setZoomLevel] = useState(1);
  
  
  // Filter Initialize
    const [showFilter, setShowFilter] = useState(false);
  useEffect(() => {
    setTitle('Centrifuge Sets');
  }, [setTitle]);


  // Fetch Data Function from backend
  const fetchData = async (link) => {
    try {
      setLoading(true);

      const response = await axios.get(`/client/api/dashboard/sidebar/${link}`);
      let fetchedData = response.data;

     // Add the categoryLink to each set
    const updatedData = fetchedData.map(set => ({
      ...set,
      categoryLink: link, // Assign the categoryLink from the current category
    }));

    // Sort the fetched data based on the default sortOrder ("new-to-old")
    const sortedData = [...updatedData].sort((a, b) => {
      if (sortOrder === 'new-to-old') {
        return new Date(b.creationDate) - new Date(a.creationDate);
      } else {
        return new Date(a.creationDate) - new Date(b.creationDate);
      }
    });

      setCentrifugeSets(sortedData);

      const initialImageLoading = response.data.reduce((acc, set) => {
        acc[set.uuid] = true;
        return acc;
      }, {});
      setImageLoading(initialImageLoading);

      const initialImagesLoaded = response.data.reduce((acc, set) => {
        acc[set.uuid] = false;
        return acc;
      }, {});
      setImagesLoaded(initialImagesLoaded);

      setSkeletonCount(Math.max(response.data.length, 6));
      // new code to show category banner
      // Update selectedSetType based on the fetched data
      if (sortedData.length > 0) {
        setSelectedSetType(sortedData[0].type);
      }
    } catch (error) {
      console.error('Error fetching centrifuge sets:', error);
      setError('Failed to fetch centrifuge sets.');
    } finally {
      setLoading(false);
    }
  };

   // new code to show category banner

   useEffect(() => {
    if (centrifugeSets.length > 0) {
      setSelectedSetType(centrifugeSets[0].type); // Example: Update to the type of the first item in bagSets
    }
  }, [centrifugeSets]);

    // new code to show category banner

  useEffect(() => {
    setTitle('Tubing Sets');
    const selectedCategory = categories[8];
    setCategoryName(selectedCategory.name);
    setCategoryLink(selectedCategory.link);

    if (selectedCategory.link) {
      fetchData(selectedCategory.link);
    }
  }, [setTitle]);

   

  // const sortCentrifugeSets = (data, order) => {
  //   return [...data].sort((a, b) => {
  //     if (order === 'new-to-old') {
  //       return new Date(b.creationDate) - new Date(a.creationDate);
  //     } else {
  //       return new Date(a.creationDate) - new Date(b.creationDate);
  //     }
  //   });
  // };

  const handleImageLoad = (uuid) => {
    setImageLoading((prev) => ({ ...prev, [uuid]: false }));
    setImagesLoaded((prev) => ({ ...prev, [uuid]: true }));
  };

  const handleImageError = (e, uuid) => {
    e.target.onerror = null;
    setFailedImages((prev) => ({ ...prev, [uuid]: true }));
    setImagesLoaded((prev) => ({ ...prev, [uuid]: false }));
  };

  // const allImagesLoaded = Object.values(imagesLoaded).every(status => status === true);

  // const handleSortChange = (event) => {
  //   setSortOrder(event.target.value);
  //   setCentrifugeSets((prevCentrifugeSets) => sortCentrifugeSets(prevCentrifugeSets, event.target.value));
  // };

  const handleOpenPopup = (setType, uuid) => {
    const selectedSet = centrifugeSets.find((s) => s.uuid === uuid); // Use `centrifugeSets` instead of `sets`
    setSelectedSetType(setType);
    setSelectedSetSKU(selectedSet?.cfdSKU || '');
    setSelectedUUID(uuid);
    setSelectedSet(selectedSet); // Pass selected set
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };


  // Filter 
const {
  data: filteredData,
  filters,
  appliedFilters,
  filterApplied,
  setFilters,
  setAppliedFilters, // Now available
  applyFilters,
  resetFilters,
  handleSortChange: handleSortChangeFromHook,
  extractFilterOptions
} = useFilterAndSort(centrifugeSets, commonSetFields.fields, storageKey);


 // Handle sort change
 const handleSortChange = (event) => {
  const order = event.target.value;
  setSortOrder(order); // Update the sortOrder state

  // Sort the centrifugeSets based on the selected order
  const sortedData = [...centrifugeSets].sort((a, b) => {
    if (order === 'new-to-old') {
      return new Date(b.creationDate) - new Date(a.creationDate);
    } else {
      return new Date(a.creationDate) - new Date(b.creationDate);
    }
  });

  // Update tubingSets with the sorted data
  setCentrifugeSets(sortedData);

  // Apply the sorting to the filteredData as well
  handleSortChangeFromHook(order); // Call the hook to apply sorting to filteredData
};

useEffect(() => {
  if (centrifugeSets.length > 0) {
    const sortedData = [...centrifugeSets].sort((a, b) => {
      if (sortOrder === 'new-to-old') {
        return new Date(b.creationDate) - new Date(a.creationDate);
      } else {
        return new Date(a.creationDate) - new Date(b.creationDate);
      }
    });

    setCentrifugeSets(sortedData);
  }
}, [sortOrder]); 


// Open Image Popup Zoom Function and Model Open
const handleOpenImagePopup = (image, set) => {
  setSelectedImage(image);
  setSelectedSet(set);
  setIsImagePopupOpen(true);
  setZoomLevel(1);
};

const handleCloseImagePopup = () => {
  setIsImagePopupOpen(false);
};

const handleZoomIn = () => setZoomLevel((prev) => Math.min(prev + 0.2, 3));
const handleZoomOut = () => setZoomLevel((prev) => Math.max(prev - 0.2, 1))

  
  if (loading) {
    return (
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-black">{categoryName} Customer Facing Drawing</h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {Array.from({ length: skeletonCount }).map((_, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden border">
              <CentrifugeSetSkeletonLoader />
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-red-500 py-4">{error}</div>;
  }

  return (
    <div className="p-6">
        <Helmet>
        <title>Custom Sut Client Area | Centrifuge Sets</title>
        <meta name="description" content="Custom Sut Client Area | Tubing Sets" />
        <meta property="og:title" content="Custom Sut Client Area | Tubing Sets" />
        <meta property="og:description" content="Custom Sut Client Area | Tubing Sets" />
        <link rel="icon" href={favicon} /> {/* Use the imported favicon */}
      </Helmet>
      <BannerDisplay selectedSetType={selectedSetType} />  
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-black">{categoryName} Customer Facing Drawing</h1>
        {/* Filter Sort By and total length common components  */}
        <CommonFilters
        filterApplied={filterApplied}
        filteredData={filteredData}
        defaultSets={centrifugeSets}
        sortOrder={sortOrder}
        handleSortChange={handleSortChange}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        appliedFilters={appliedFilters}
        resetFilters={resetFilters}
      />
      </div>

       {/* Apply the reusable AppliedFilters component */}
       <AppliedFilters 
        appliedFilters={appliedFilters}
        setFilters={setFilters}
        setAppliedFilters={setAppliedFilters}
        resetFilters={resetFilters}
        fields={commonSetFields.fields} 
        storageKey={storageKey}
      />
       {/* Use the InfoLabel component Bottom Info */}
       <InfoLabel/>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {(filterApplied ? filteredData : centrifugeSets).length > 0 ? (
          (filterApplied ? filteredData : centrifugeSets).map((set) => (
            <div
            key={set.uuid}
            className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300"
            style={{ border: '1px solid #005f9e' }} // Add custom border color
            >
               {/* Image Card */}
            <div className="relative group">
              {failedImages[set.uuid] ? (
                <ImageSkeletonLoader className="w-full h-48" />
              ) : (
                <>
                  <img
                    src={set.cfdImage}
                    alt={`${set.type} image`}
                    className="w-full h-100 object-contain"
                    onLoad={() => handleImageLoad(set.uuid)}
                    onError={(e) => handleImageError(e, set.uuid)}
                    style={{ display: imageLoading[set.uuid] ? 'none' : 'block' }}
                    onContextMenu={(e) => e.preventDefault()} // Disable right-click menu
                    draggable={false} // Prevent drag-and-drop
                  />
                  <div
                    className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 cursor-pointer"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOpenImagePopup(set.cfdImage, set);
                    }}
                  >
                    <ZoomInIcon className="text-white text-5xl" />
                  </div>
                </>
              )}
              <div className="absolute top-0 right-0 bg-black bg-opacity-30 text-white p-2">
                <h2 className="text-xl font-semibold">{set.type}</h2>
              </div>
            </div>
            <RecentlyViewedWrapper set={set}>
              <Link to={`/v1/portal/csutc/dashboard/${categoryLink}/details/${set.uuid}`}>
              {/* Drawing title */}
                                                 <div className='pl-2 pr-1'>
                                                   {commonSetFields.fields.map((field, index) => {
                                                      if (field.field === 'categories') {
                                                        return (
                                                        <div key={index}>
                                                         <h2 className={`text-primary px-2 text-xl py-1 font-bold capitalize`}>
                                                         {String(set.categories || '').toLowerCase()} {/* Ensure it's a string */}
                                                          </h2>
                                                          </div>
                                                        );
                                                       }
                                                      })}
                                                </div>
                <div className="p-4">
                {commonSetFields.fields.map((field, index) => {
                  const IconComponent = iconMapping[field.field] || Icon;

              // Check if the field is 'categories' and render it differently
              if (field.field === 'categories') {
                return (
                  <p key={index} className="text-gray-700 mb-2 flex items-center">
                    <CategoryIcon className={classes.icon} />
                    <span className="font-500">{field.label}:</span>
                    
                    <CategorySlider
                      categories={set[field.field]?.flatMap(category => category.split(',').map(cat => cat.trim())) || []}
                    />
                  </p>
                );
              }

              // For other fields, use the default rendering
              return (
                <p key={index} className="text-gray-700 mb-2 flex items-center">
                  {IconComponent && <IconComponent className={classes.icon} />}
                  {field.label}:&nbsp;
                  <strong>{set[field.field] || 'N/A'}</strong>
                </p>
              );
            })}
          </div>
              </Link>
              </RecentlyViewedWrapper>
              <div className={classes.buttonGroup}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenPopup(set.type, set.uuid)}
              >
                Make Inquiry
              </Button>
              <RecentlyViewedWrapper set={set}>
              <Link to={`/v1/portal/csutc/dashboard/${categoryLink}/details/${set.uuid}`}>
              <Button variant="contained" className={classes.viewDetailsButton}>
                View Details
              </Button>
            </Link>
            </RecentlyViewedWrapper>
               {/* Wishlist Button */}
               <WishlistButton set={set} />
              </div>
            </div>
          ))
        ) : (
          <div className="text-center col-span-full py-4 text-gray-500">No items available.</div>
        )}
      </div>
      <EnquiryPopup
        open={popupOpen}
        handleClose={handleClosePopup}
        selectedSet={selectedSet}
        setSelectedSetType={selectedSetType}
        selectedSetSKU={selectedSetSKU}
      />
        {/* Model Image Popup Function to open card image with data when click on image  */}
       {/* Model Image Popup Function to open card image with data when click on image  */}
       <ImagePopup
        isImagePopupOpen={isImagePopupOpen}
        selectedImage={selectedImage}
        zoomLevel={zoomLevel}
        handleCloseImagePopup={handleCloseImagePopup}
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        selectedSet={selectedSet}
        setSelectedSet={setSelectedSet} // Pass this as a prop
        setZoomLevel={setZoomLevel}     // Pass this as a prop
        handleOpenPopup={handleOpenPopup}
        categoryLink={categoryLink}
        // Exclude the selectedSet from allSets or filteredData
        allSets={filterApplied ? filteredData.filter(set => set.uuid !== selectedSet?.uuid) : centrifugeSets.filter(set => set.uuid !== selectedSet?.uuid)}
       />
      {/* Model Image Popup Function to open card image with data when click on image  */}
      <FilterSidebar
        open={showFilter}
        onClose={() => setShowFilter(false)}
        filters={filters}
        setFilters={setFilters}
        availableFilters={extractFilterOptions(centrifugeSets)} 
        applyFilters={applyFilters}
        resetFilters={resetFilters}
        fields={commonSetFields.fields} 
        storageKey={storageKey}
      />
    </div>
  );
};

export default CentrifugeSets;


