// src/components/ExitPopup/ExitPopup.js
import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, CircularProgress, Tooltip } from "@mui/material"; // Import CircularProgress for loading indication
import { Link } from "react-router-dom"; // Import Link for navigation
import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflowY: 'auto',
    border: '2px solid #0171BB', // Primary brand color
    borderRadius: '16px', // Add border radius
};

const cardContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    overflowY: 'scroll',
    gap: '16px', // Gap between sections
};

const cardRowStyle = {
    display: 'flex',
    flexWrap: 'wrap', // Enable multiple cards per row
    gap: '10px', // Gap between cards set to 10px
};

// Function to dynamically set card styles
const cardStyle = (isLastViewed) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: `1px solid ${isLastViewed ? 'blue' : 'lightgray'}`,
    borderRadius: '8px',
    padding: '8px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    minWidth: '200px',
    cursor: 'pointer', // Change cursor to indicate clickable cards
    flex: '1 1 calc(33.333% - 10px)', // Use flex for responsive width
});

// Additional styles for image
const imageStyle = {
    width: '50px',
    height: '50px',
    marginRight: '8px',
    border: '1px solid lightgray', // Add a border to the image
};

// Truncate Categories Function
const truncateText = (text, maxLength = 30) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

const ExitPopup = ({ open, onClose, onLeave, reloadOnOpen }) => {
    const [recommendations, setRecommendations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [selectedDrawing, setSelectedDrawing] = useState(null); // State to track the selected drawing

    // Fetch user data including email
    const fetchUserData = async () => {
        try {
            const response = await axios.get('/client/api/dashboard/user-profile-header');
            const { email: userEmail } = response.data;
            setEmail(userEmail);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    // Fetch user data and recommendations when popup opens
   // Inside the ExitPopup component
    useEffect(() => {
       // console.log('Modal opened, email:', email); // To confirm the email being sent
        if (open) {
            fetchUserData();
        }
    }, [open]);

    // Fetch recommendations when the email is available and on modal open
   // After fetching recommendations
    useEffect(() => {
        if (reloadOnOpen) {
            fetchRecommendations();
            console.log('Fetching recommendations...');
        }
    }, [email, reloadOnOpen]);

  // Fetch recommendations using the email
  const fetchRecommendations = async () => {
    if (!email) {
        console.warn('Email is not available to fetch recommendations.'); // Warning log
        return;
    }
    setLoading(true);
    try {
        const response = await axios.get(`/client/api/dashboard/exit-popup/${email}`);
        setRecommendations(response.data);
    } catch (error) {
        console.error('Error fetching recommendations:', error);
    } finally {
        setLoading(false);
    }
   };

    // Filter for recently viewed and regular recommendations
    const recentlyViewed = recommendations.filter(r => r.isLastViewed);
    const otherRecommendations = recommendations.filter(r => !r.isLastViewed);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={style}>
                {loading ? (
                    <CircularProgress /> // Show loading indicator
                ) : (
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>
                            <Typography variant="h5" style={{ margin: 0 }}>
                                Leaving Already?
                            </Typography>
                            <div>
                                <Button onClick={onClose} color="primary" sx={{ mt: 2 }}>
                                    Stay on this page
                                </Button>
                                <Button onClick={onLeave} color="error" sx={{ mt: 2, ml: 1 }}>
                                    Leave
                                </Button>
                            </div>
                        </div>

                        {/* Section for Recently Viewed Items */}
                        {recentlyViewed.length > 0 && (
                            <>
                                <Typography variant="h6" style={{ marginBottom: '4px' }}>
                                    If you liked your recent viewed,
                                </Typography>
                                <div className="recently-viewed-cards" style={cardRowStyle}>
                                    {recentlyViewed.map((drawing) => (
                                         <Tooltip title={drawing.categories.join(', ')} placement="top">
                                        <Link
                                            key={drawing.uuid}
                                            to={`/v1/portal/csutc/dashboard/${drawing.typeLink}/details/${drawing.uuid}?refer=intentPopups&recomm&drwno=${drawing.cfdSKU}&id=${drawing._id}`}
                                            onClick={() => {
                                                setSelectedDrawing(drawing);
                                                onClose(); // Close modal
                                            }}
                                            style={{ textDecoration: 'none' }} // Remove underline
                                        >
                                            <div style={cardStyle(drawing.isLastViewed)}>
                                                <img src={drawing.cfdImage} alt={drawing.cfdDescription} style={imageStyle} />
                                                <div>
                                                    <h3 style={{ margin: 0, fontSize: 'small' }}>{drawing.cfdSKU}</h3>
                                                   
                                                        <p style={{ margin: 0, fontSize: 'small' }}>
                                                            {truncateText(drawing.categories.join(', '))}
                                                        </p>
                                                  

                                                    {/* Overlay for selected drawing */}
                                                    {selectedDrawing && selectedDrawing.uuid === drawing.uuid && (
                                                        <div style={{
                                                            position: 'absolute',
                                                            top: '10%',
                                                            left: '10%',
                                                            right: '10%',
                                                            backgroundColor: '#e0f7e0', // Light green background
                                                            borderRadius: '8px',
                                                            padding: '8px',
                                                            textAlign: 'center',
                                                            zIndex: 10,
                                                        }}>
                                                            <Typography variant="body1" style={{ margin: 0, color: '#007700' }}>
                                                                <span role="img" aria-label="tick">✅</span> Great choice!
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Link>
                                        </Tooltip>
                                    ))}
                                </div>
                            </>
                        )}

                        {/* Section for Other Recommendations */}
                        {otherRecommendations.length > 0 && (
                            <>
                                <Typography variant="h6" style={{ marginTop: '16px', marginBottom: '4px' }}>
                                    Can we help you with another similar drawings?
                                </Typography>
                                <div className="other-recommendations-cards" style={cardRowStyle}>
                                    {otherRecommendations.map((drawing) => (
                                        <Link
                                            key={drawing.uuid}
                                            to={`/v1/portal/csutc/dashboard/${drawing.typeLink}/details/${drawing.uuid}?refer=intentPopups&recomm&drwno=${drawing.cfdSKU}&id=${drawing._id}`}
                                            onClick={() => {
                                                setSelectedDrawing(drawing);
                                                onClose(); // Close modal
                                            }}
                                            style={{ textDecoration: 'none' }} // Remove underline
                                        >
                                            <div style={cardStyle(drawing.isLastViewed)}>
                                                <img src={drawing.cfdImage} alt={drawing.cfdDescription} style={imageStyle} />
                                                <div>
                                                    <h3 style={{ margin: 0, fontSize: 'small' }}>{drawing.cfdSKU}</h3>
                                                    <p style={{ margin: 0, fontSize: 'small' }}>{truncateText(drawing.categories.join(', '))}</p>

                                                    {/* Overlay for selected drawing */}
                                                    {selectedDrawing && selectedDrawing.uuid === drawing.uuid && (
                                                        <div style={{
                                                            position: 'absolute',
                                                            top: '10%',
                                                            left: '10%',
                                                            right: '10%',
                                                            backgroundColor: '#e0f7e0', // Light green background
                                                            borderRadius: '8px',
                                                            padding: '8px',
                                                            textAlign: 'center',
                                                            zIndex: 10,
                                                        }}>
                                                            <Typography variant="body1" style={{ margin: 0, color: '#007700' }}>
                                                                <span role="img" aria-label="tick">✅</span> Great choice!
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </>
                        )}

                        {/* No Recommendations Available Message */}
                        {recentlyViewed.length === 0 && otherRecommendations.length === 0 && (
                            <Typography variant="body1">No recommendations available.</Typography>
                        )}
                    </div>
                )}
            </Box>
        </Modal>
    );
};

export default ExitPopup;



