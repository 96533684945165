import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTitle } from '../../Contexts/TitleContext';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { Select, MenuItem, FormControl, InputLabel, Button, CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet';
import useFavicon from '../../../hooks/useFavicon';
import favicon from '../../../assets/images/favicon.png';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteOptionsPopup from './RecentlyViewedDeletepPopup';
import { toast } from 'react-toastify';

const RecentlyViewed = () => {
    useFavicon(favicon);
    const { setTitle } = useTitle();
    const [recentlyViewed, setRecentlyViewed] = useState([]);
    const [filteredViewed, setFilteredViewed] = useState([]);
    const [userEmail, setUserEmail] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');
    const [categories, setCategories] = useState([]);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false); // New state variable
  
    // Set Page title in header
    useEffect(() => {
      setTitle('Recently Viewed CFD Diagrams');
    }, [setTitle]);
  
    // fetch user records from server
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get('/client/api/dashboard/user-profile-header');
        if (response.data?.email) setUserEmail(response.data.email);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

   // fetch recently viewed records from server
   const fetchRecentlyViewed = async (email) => {
        setIsLoading(true); // Start loading immediately
        setIsDataFetched(false); // Reset data fetched state
        try {
            const response = await axios.get(`/client/api/dashboard/get-recently-viewed/${email}`);
            setRecentlyViewed(response.data);
            setFilteredViewed(response.data);
            updateCategories(response.data);
        } catch (error) {
            console.error('Error fetching recently viewed products:', error);
        } finally {
            setIsLoading(false); // End loading
            setIsDataFetched(true); // Set to true after data is fetched
        }
     };

   // called fetchUserProfile function here 
    useEffect(() => {
      fetchUserProfile();
      if (userEmail) fetchRecentlyViewed(userEmail);
    }, [userEmail]);

     // update categories after deletion
    const updateCategories = (data) => {
        const uniqueCategories = [...new Set(data.map(item => item.categoryLink))];
        setCategories(uniqueCategories);
        if (uniqueCategories.length === 0) {
          setCategoryFilter('');
        }
      };
  
      const handleCategoryChange = (event) => {
        const selectedCategory = event.target.value;
        setCategoryFilter(selectedCategory);
        setFilteredViewed(
          selectedCategory
            ? recentlyViewed.filter(item => item.categoryLink === selectedCategory)
            : recentlyViewed
        );
      };
  
    // Delete records by all
    const handleDeleteAll = async () => {
      try {
        await axios.delete(`/client/api/dashboard/get-recently-viewed/${userEmail}`);
        setFilteredViewed([]);
        setShowDeletePopup(false);
        toast.success('All recently viewed items deleted successfully.');
        updateCategories([]); // Clear categories
      } catch (error) {
        console.error('Error deleting all items:', error);
      }
    };
  
   // Delete records by category
    const handleDeleteByCategory = async (selectedCategories) => {
        try {
          for (const category of selectedCategories) {
            await axios.delete(`/client/api/dashboard/get-recently-viewed/${userEmail}/${category}`);
          }
          const remainingItems = recentlyViewed.filter(
            item => !selectedCategories.includes(item.categoryLink)
          );
          setFilteredViewed(remainingItems);
          setShowDeletePopup(false);
          toast.success('Selected categories deleted successfully.');
          updateCategories(remainingItems);
        } catch (error) {
          console.error('Error deleting items by category:', error);
        }
      };

    // Utility function to format category names
    const formatCategory = (category) => {
        if (!category) return '';
        return category
          .split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      };


   // Add loader to utlize waiting time
   if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <CircularProgress size={50} />
      </div>
    );
  }

  // const formatTimeDifference = (date) => {
  //   const now = new Date();
  //   const diffInSeconds = Math.floor((now - new Date(date)) / 1000);
  
  //   const hours = Math.floor(diffInSeconds / 3600);
  //   const minutes = Math.floor((diffInSeconds % 3600) / 60);
  //   const seconds = diffInSeconds % 60;
  
  //   let result = '';
  //   if (hours > 0) result += `${hours} hr `;
  //   if (minutes > 0) result += `${minutes} min `;
  //   if (seconds > 0) result += `${seconds} sec `;
    
  //   return result.trim() + ' ago';
  // };

  const formatTimeDifference = (dateString, prefix = '') => {
      const date = parseISO(dateString);
      return `${prefix} ${formatDistanceToNow(date, { addSuffix: true })}`;
  };
  
  
  return (
    <div className="recently-viewed p-6">
    {/* Meta OG Title Favicon load under head */}    
      <Helmet>
        <title>Recently Viewed CFD Diagrams</title>
        <meta name="description" content="Custom Sut Client Area | Recently Viewed CFD Diagrams" />
        <meta property="og:title" content="Custom Sut Client Area | Recently Viewed CFD Diagrams" />
        <meta property="og:description" content="Custom Sut Client Area | Recently Viewed CFD Diagrams" />
        <link rel="icon" href={favicon} />
      </Helmet>

       {/* Show filter and delete options only if there are categories */}
        {/* show loader first to utlize server waiting time */}
       {!isLoading && isDataFetched && (
       <>
       {categories.length > 0 && (
        <div className="mb-6">
        {/* Title with total length  count intial or filtered both */}
        <h2 className="text-2xl font-semibold mb-6 flex justify-between items-center">
          Recently Viewed CFD Diagrams
            {filteredViewed.length > 0 && (
                <span className="text-gray-400 font-normal text-sm">
                {categoryFilter
                    ? `Total CFD Diagram Viewed for ${formatCategory(categoryFilter)}`
                    : 'Total CFD Diagram Viewed'} 
                <strong> ({filteredViewed.length})</strong>
                </span>
            )}
            </h2>
           {/* Category Filter */}
           <FormControl variant="outlined" fullWidth className="mb-4">
        <InputLabel 
            id="category-select-label" 
            shrink={true} // Always shrink to avoid overlap
            style={{ backgroundColor: 'white', padding: '0 8px', transform: 'translate(14px, -6px) scale(0.75)' }}
        >
            Filter Recently Viewed by CFD Sets Category
        </InputLabel>
        <Select
            labelId="category-select-label"
            value={categoryFilter}
            onChange={handleCategoryChange}
            displayEmpty
            renderValue={(selected) => selected ? formatCategory(selected) : 'All Categories'}
            MenuProps={{
            PaperProps: {
                style: {
                maxHeight: 300, // Limit dropdown height for better visibility
                },
            },
            }}
        >
            <MenuItem value="">
            <em>All Categories</em>
            </MenuItem>
            {categories.map((category, index) => (
            <MenuItem key={index} value={category}>
                {formatCategory(category)}
            </MenuItem>
            ))}
        </Select>
        </FormControl>

         {/* Delete Button */}
          <Button
            variant="outlined"
            startIcon={<DeleteOutlineIcon />}
            color="secondary"
            onClick={() => setShowDeletePopup(true)}
            style={{
              borderColor: '#f44336',
              color: '#f44336',
              backgroundColor: 'transparent',
              transition: 'background-color 0.3s, color 0.3s',
            }}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = '#f4433610';
              e.target.style.color = '#d32f2f';
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = 'transparent';
              e.target.style.color = '#f44336';
            }}
          >
            Delete Recently Viewed Record
          </Button>
        </div>
      )}
        {/* Delete Confirmation Popup */}
        {showDeletePopup && (
        <DeleteOptionsPopup
          categories={categories}
          onDeleteAll={handleDeleteAll}
          onDeleteByCategory={handleDeleteByCategory}
          onCancel={() => setShowDeletePopup(false)}
        />
      )}

      {/* Recently Viewed Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {filteredViewed.length > 0 ? (
        filteredViewed.map((product) => (
        <div
         key={product.uuid}
         className="flex bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow duration-300 border-2 border-[#0171BB]"
            >

        {/* Image Section */}
        <div className="w-1/3">
          <img
            src={product.cfdImage}
            alt={product.type}
            className="w-full h-full object-contain"
          />
        </div>

        {/* Product Info */}
        <div className="w-2/3 p-4 flex flex-col justify-between">
          {/* Last viewed label */}
        <div className="text-xs text-white mb-2 bg-blue-500 px-2 py-1 rounded-br-lg z-1">
        {product.viewedAt && !isNaN(Date.parse(product.viewedAt)) ? (
            `Last viewed: ${formatTimeDifference(product.viewedAt)}`
        ) : (
            'Last viewed: Unknown'
        )}
        </div>


          {/* Product Details */}
          <h3 className="text-lg font-semibold mb-2">{product.type}</h3>
          <p className="text-gray-600 text-sm mb-1">Drawing No: {product.cfdSKU}</p>

          {/* View Details Link */}
          <div className="flex flex-col space-y-3 mt-2">
            {/* Link to view set details */}
            <Link
                to={`/v1/portal/csutc/dashboard/${product.categoryLink}/details/${product.uuid}`}
                className="inline-flex items-center justify-center px-4 py-2 border border-[#0171BB] text-[#0171BB] font-semibold rounded-md hover:bg-[#0171BB] hover:text-white transition duration-300 ease-in-out"
            >
                View Set Details
            </Link>

            {/* Link to view all sets */}
            <Link
            to={`/v1/portal/csutc/dashboard/${product.categoryLink}`}
            className="text-[#0171BB] hover:underline inline-flex items-center justify-center"
            >
                View all sets drawing
            </Link>
          </div>


        </div>
      </div>
     ))
    ) : (
      <div className="col-span-full text-center text-gray-500">No recently viewed CFD diagrams available.</div>
    )}
  </div>
</>
)}
</div>
);
};

export default RecentlyViewed;

    
