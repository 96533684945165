// src/components/InfoLabel.js
import React from 'react';
import InfoIcon from '@mui/icons-material/Info'; // Import the Info icon

const InfoLabel = ({ backgroundColor = '#3f51b5' }) => {
  return (
    <div>
      {/* Main InfoLabel content */}
      <div
        className="relative w-full mb-5 p-2 text-white text-center flex items-center justify-center gap-2"
        style={{ backgroundColor }} // Use the provided background color
      >
        <InfoIcon fontSize="small" /> {/* Info icon */}
        <div className="text-center text-sm text-white-600 mt-1">
        This is a customer facing marketing drawing and does not represent our complete detail engineering drawings provided for signature.
      </div>
      </div>
    </div>
  );
};

export default InfoLabel;