// src/utils/resultClickHandler.js
import { useNavigate, useLocation } from 'react-router-dom';

const useResultClickHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleResultClick = (typeLink, uuid, query) => {
    const searchParams = new URLSearchParams(location.search);
    
    // Preserve the original search query
    const originalQuery = searchParams.get('k') || query;

    // Construct the new URL while keeping the original search query intact
    const searchUrl = `/v1/portal/csutc/dashboard/${typeLink}/details/${uuid}?k=${originalQuery}&ref=searchbar`;

    console.log('Navigating to URL:', searchUrl);
    navigate(searchUrl);
};


  return handleResultClick;
};

export default useResultClickHandler;

