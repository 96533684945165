// src/App.js
import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from 'axios';
import Layout from "./components/Layout/Layout";
import Dashboard from "./components/Dashboard/Dashboard";
import TubingSets from "./components/Sidebar/TubingSets/TubingSets";
import FilterSets from "./components/Sidebar/FilterSets/FilterSets";
import NotFound from "./components/NotFound/NotFound";
import TubingSetDetails from "./components/Sidebar/TubingSets/TubingSetDetails/TubingSetDetails";
import FilterSetDetails from "./components/Sidebar/FilterSets/FilterSetDetails/FilterSetDetails";
import BagSets from "./components/Sidebar/BagSets/BagSets";
import BagSetDetails from "./components/Sidebar/BagSets/BagSetDetails/BagSetDetails";
import Logout from "./components/Logout/Logout";
import Disapproved from "./components/Disapproved/Disapproved";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import RedirectHandler from "./components/RedirectHandler/RedirectHandler";
import UserProfile from "./components/UserProfile/UserProfile";
import { TitleProvider } from './components/Contexts/TitleContext';
import ScrollToTop from './hooks/ScrollToTop'; 
import OfflineMessage from "./components/OfflineMessage/OfflineMessage";
import OnlineMessage from "./components/OnlineMessage/OnlineMessage";
import GetAllUserInquiries from './components/Common/GetAllUserInquiries'
import NeedleSets from "./components/Sidebar/NeedleSets/NeedleSets";
import NeedleSetDetails from "./components/Sidebar/NeedleSets/NeedleSetDetails/NeedleSetDetails";
import BottleSets from "./components/Sidebar/BottleSets/BottleSets";
import BottleSetDetails from "./components/Sidebar/BottleSets/BottleSetDetails/BottleSetDetails";
import BottleManifoldSets from "./components/Sidebar/BottleManifoldSets/BottleManifoldSets";
import BottleManifoldSetDetails from "./components/Sidebar/BottleManifoldSets/BottleMSetDetails/BottleMSetDetails";
import CarboySets from "./components/Sidebar/CarboySets/CarboySets";
import CarboySetDetails from "./components/Sidebar/CarboySets/CarboySetDetails/CarboySetDetails";
import FlaskSets from "./components/Sidebar/FlaskSets/FlaskSets";
import FlaskSetDetails from "./components/Sidebar/FlaskSets/FlaskSetDetails/FlaskSetDetails";
import CentrifugeSets from "./components/Sidebar/CentrifugeSets/CentrifugeSets";
import CentrifugeSetDetails from "./components/Sidebar/CentrifugeSets/CentrifugeSetDetails/CentrifugeSetDetails";
import WeldedSets from "./components/Sidebar/WeldedSets/WeldedSets";
import WeldedSetDetails from "./components/Sidebar/WeldedSets/WeldedSetDetails/WeldedSetDetails";
import MixingSets from "./components/Sidebar/MixingSets/MixingSets";
import MixingSetDetails from "./components/Sidebar/MixingSets/MixingSetDetails/MixingSetDetails";
import JumperSets from "./components/Sidebar/JumperSets/JumperSets";
import JumperSetDetails from "./components/Sidebar/JumperSets/JumperSetDetails/JumperSetDetails";
import CapAssembliesSets from "./components/Sidebar/CapAssembliesSets/CapAssembliesSets";
import CapAssembliesSetDetails from "./components/Sidebar/CapAssembliesSets/CapAssembliesSetDetails/CapAssembliesSetDetails";
import CarboyManifoldSets from "./components/Sidebar/CarboyManifoldSets/CarboyManifoldSets";
import CarboyManifoldSetDetails from "./components/Sidebar/CarboyManifoldSets/CarboyManifoldSetDetails/CarboyManifoldSetDetails";
import CustomSets from "./components/Sidebar/CustomSets/CustomSets";
import CustomSetDetails from "./components/Sidebar/CustomSets/CustomSetDetails/CustomSetDetails";
import StickyBottomPopup from './components/StickyBottomPopup/CookiePolicyStickyBottomPopup';
import RecentlyViewed from "./components/Common/RecentlyViewedCard/RecentlyViewed";

import Wishlist from "./components/Common/WishlistCard/Wishlist";
import { WishlistProvider } from './components/Contexts/WishlistProvider';

// import QuinnVideo from "./components/Video/QuinnVideo";

import DynamicRedirect from './components/PrivateRoute/DynamicRedirect';

import SearchResults from './components/SearchBar/SearchResults'; 
import LatestSetsDrawer from "./components/Header/LatestSetsDrawer";

import ExitPopup from "./components/ExitPopup/ExitPopup";

import { useExitIntent } from "use-exit-intent"; // Use named import

import { Modal, Box, Typography, Button } from "@mui/material";

// Utility function to check internet connectivity
const checkInternetConnection = async () => {
  try {
    // Fetch a lightweight resource that allows CORS
    const response = await fetch('https://jsonplaceholder.typicode.com/todos/1', { method: 'GET' });
    // If response is successful, return true
    return response.ok;
  } catch (error) {
    return false; // No internet connection if fetch fails
  }
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};


function App() {
  const [online, setOnline] = useState(navigator.onLine);
  const [showOnlineMessage, setShowOnlineMessage] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  
  const [hasTriggered, setHasTriggered] = useState(false); // Track if exit intent was triggered
  const { registerHandler } = useExitIntent();

  useEffect(() => {
    const handleOnline = async () => {
      const hasInternet = await checkInternetConnection();
      setOnline(hasInternet);
      if (hasInternet) {
        setShowOnlineMessage(true);
        setTimeout(() => {
          setShowOnlineMessage(false);
        }, 3000); // Show the online message for 3 seconds
      }
    };

    const handleOffline = () => setOnline(false);

    // Event listeners for detecting online/offline changes
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Check internet connection periodically
    const intervalId = setInterval(async () => {
      const hasInternet = await checkInternetConnection();
      setOnline(hasInternet);
    }, 1000); // Check every 5 seconds


    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      clearInterval(intervalId); // Cleanup the interval when component unmounts
    };
  }, []);



  // Function to handle clicking the close tab button
 
 // Exit intent handler
// Define your modal handler function independently
  // Use effect to handle exit intent registration
  useEffect(() => {
    const openModalHandler = () => {
        if (!hasTriggered) { // Only trigger if it hasn't been triggered
            console.log("Hello from handler!"); // Log the message to the console
            setOpenModal(true); // Open the modal
            setHasTriggered(true); // Mark as triggered to avoid multiple openings
        }
    };

    // Register the exit intent handler
    registerHandler({
        id: "openModal",
        handler: openModalHandler,
    });

    // Cleanup function
    return () => {
        // If useExitIntent has an unregisterHandler method, use it
        // unregisterHandler("openModal");
    };
}, [registerHandler, hasTriggered]); // Dependency includes hasTriggered

// Mouse event listener for better exit detection
useEffect(() => {
    const handleMouseOut = (event) => {
        if (!hasTriggered && event.clientY < 0) { // User moving mouse to the top edge
            console.log("Mouse moved out, triggering exit intent.");
            setOpenModal(true); // Open the modal
            setHasTriggered(true); // Prevent multiple triggers
        }
    };

    window.addEventListener("mouseout", handleMouseOut);

    return () => {
        window.removeEventListener("mouseout", handleMouseOut);
    };
}, [hasTriggered]); // Cleanup on unmount

const closeModal = () => {
    setOpenModal(false); // Close the modal
    setHasTriggered(false); // Reset trigger state when modal closes
};

const handleLeave = () => {
  console.log("User chose to leave.");
  setOpenModal(false);

  // Open a blank tab first
  const newTab = window.open('about:blank', '_blank');
  
  // Immediately close the original tab
  window.close();

  // Fallback if original tab couldn't close
  setTimeout(() => {
    newTab.close();
    window.location.href = 'about:blank'; // Empty current page as last resort
  }, 100);
};

  
  // existing code


  const retryConnection = async () => {
    const hasInternet = await checkInternetConnection();
    setOnline(hasInternet);
  };

  if (!online) {
    return <OfflineMessage onRetry={retryConnection} />;
  }

  if (showOnlineMessage) {
    return <OnlineMessage />;
  }

  return (
    <WishlistProvider>
    <Router>
       <StickyBottomPopup />
       <TitleProvider>
       <ScrollToTop />
       <ExitPopup 
                open={openModal} 
                onClose={closeModal} 
                onLeave={handleLeave} 
                reloadOnOpen={openModal} // Pass reload signal to ExitPopup
            />
      <Routes>
      <Route path="/" element={<RedirectHandler />} />
        <Route path="/v1/portal/csutc/dashboard" element={<Layout />}>
        <Route path="s" element={<DynamicRedirect />} />
          <Route index element={<PrivateRoute element={<Dashboard />} />} />
          <Route path="tubing-sets" element={<PrivateRoute element={<TubingSets />} />} />
          <Route path="filter-sets" element={<PrivateRoute element={<FilterSets />} />} />
          <Route path="bag-sets" element={<PrivateRoute element={<BagSets />} />} />
          <Route path="needle-sets" element={<PrivateRoute element={<NeedleSets />} />} />
          <Route path="bottle-sets" element={<PrivateRoute element={<BottleSets />} />} />
          <Route path="bottle-manifold-sets" element={<PrivateRoute element={<BottleManifoldSets />} />} />
          <Route path="carboy-sets" element={<PrivateRoute element={<CarboySets />} />} />
          <Route path="flask-sets" element={<PrivateRoute element={<FlaskSets />} />} />
          <Route path="recently-viewed" element={<PrivateRoute element={<RecentlyViewed />} />} />
          <Route path="wishlist" element={<PrivateRoute element={<Wishlist />} />} />
          <Route path="centrifuge-sets" element={<PrivateRoute element={<CentrifugeSets />} />} />
          <Route path="welded-sets" element={<PrivateRoute element={<WeldedSets />} />} />
          <Route path="mixing-sets" element={<PrivateRoute element={<MixingSets />} />} />
          <Route path="jumper-sets" element={<PrivateRoute element={<JumperSets />} />} />
          <Route path="cap-assemblies-sets" element={<PrivateRoute element={<CapAssembliesSets />} />} />
          <Route path="carboy-manifold-sets" element={<PrivateRoute element={<CarboyManifoldSets />} />} />
          <Route path="custom-sets" element={<PrivateRoute element={<CustomSets />} />} />
          <Route path="tubing-sets/details/:uuid" element={<PrivateRoute element={<TubingSetDetails />} />} />
          <Route path="filter-sets/details/:uuid" element={<PrivateRoute element={<FilterSetDetails />} />} />
          <Route path="bag-sets/details/:uuid" element={<PrivateRoute element={<BagSetDetails />} />} />
          <Route path="needle-sets/details/:uuid" element={<PrivateRoute element={<NeedleSetDetails />} />} />
          <Route path="bottle-sets/details/:uuid" element={<PrivateRoute element={<BottleSetDetails />} />} />
          <Route path="bottle-manifold-sets/details/:uuid" element={<PrivateRoute element={<BottleManifoldSetDetails />} />} />
          <Route path="carboy-sets/details/:uuid" element={<PrivateRoute element={<CarboySetDetails />} />} />
          <Route path="flask-sets/details/:uuid" element={<PrivateRoute element={<FlaskSetDetails />} />} />
          <Route path="centrifuge-sets/details/:uuid" element={<PrivateRoute element={<CentrifugeSetDetails />} />} />
          <Route path="welded-sets/details/:uuid" element={<PrivateRoute element={<WeldedSetDetails />} />} />
          <Route path="mixing-sets/details/:uuid" element={<PrivateRoute element={<MixingSetDetails />} />} />
          <Route path="jumper-sets/details/:uuid" element={<PrivateRoute element={<JumperSetDetails />} />} />
          <Route path="cap-assemblies-sets/details/:uuid" element={<PrivateRoute element={<CapAssembliesSetDetails />} />} />
          <Route path="carboy-manifold-sets/details/:uuid" element={<PrivateRoute element={<CarboyManifoldSetDetails />} />} />
          <Route path="custom-sets/details/:uuid" element={<PrivateRoute element={<CustomSetDetails />} />} />
          <Route path="account-settings/profile" element={<PrivateRoute element={<UserProfile />} />} />
          <Route path="inquiries/submitted-inquiries" element={<PrivateRoute element={<GetAllUserInquiries />} />} />
          <Route path="latest-sets-data" element={<PrivateRoute element={<LatestSetsDrawer />} />} />
          {/* <Route path="quinn-video" element={<PrivateRoute element={<QuinnVideo />} />} /> */}
          <Route path="search/s" element={<SearchResults />} />
        </Route>
        <Route path="/v1/portal/csutc/dashboard/logout" element={<Logout />} />
        <Route path="/account-center/signin-identifier" element={<div />} /> {/* Add this line */}
        <Route path="/v1/portal/csutc/dashboard/disapproved" element={<Disapproved />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      </TitleProvider>
       {/* Modal Component */}
      
    </Router>

    </WishlistProvider>
  );
}

export default App;

